import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grow,
  styled,
  Typography,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CircleIcon from '@mui/icons-material/Circle';

import YearPicker from 'components/filter/YearPicker/YearPicker';
import CheckboxControlList from 'components/filter/CheckboxControlList/CheckboxControlList';
import { ICheckboxControl } from 'components/filter/CheckboxControlList/CheckboxControlList.interface';
import { updateFilters } from 'store/actions/filters/filtersAction';
import { updateFiltersVisibility } from 'store/actions/userInfo/userAction';
import BasicModalNoButton from 'components/rootComponents/Modal/BasicModalNoButton';
import InfoService from 'services/info/info.service';
import { IFilter, IInfo, IRol, IValue } from 'services/info/info.interface';
import { standarizeString } from 'utils/helper';
import { IFilters } from './Filters.interface';
import useStyles from './Filters.styles';

const CONST_YEAR = 'year';
const CONST_ESTUDIANTE = 'estudiante';
const CONST_ITR = 'itr';
const CONST_CARRERAS = 'planes';
const CONST_PERIODO = 'periodo';
const CONST_CURSOS = 'curso-edu';
const CONST_SEMESTRE = 'semestre';
const CONST_SEDE = 'sede';
const CONST_ESTADO = 'estado';
const CONST_ASIGNATURA = 'asignaturas';
const CONST_TODOS = 'TODOS';
const CONST_TODAS = 'TODAS';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: '10px',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

/**
 * Filter view using [[CheckboxList]] and [[YearPicker]] components
 *
 * @component
 */
const Filters = (props: IFilters) => {
  const classes = useStyles();
  const history = useHistory();

  const [majorsFilter, setMajorsFilter] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedStudent, setSelectedStudent] = useState(['']);
  const [selectedITR, setSelectedITR] = useState(['']);
  const [selectedMajors, setSelectedMajors] = useState(['']);
  const [selectedPeriods, setSelectedPeriods] = useState(['']);
  const [selectedCourses, setSelectedCourses] = useState(['']);
  const [selectedSemester, setSelectedSemester] = useState(['']);
  const [selectedState, setSelectedState] = useState(['']);
  const [selectedAsignaturas, setSelectedAsignaturas] = useState(['']);
  const [selectedCampus, setSelectedCampus] = useState(['']);
  const [showFilters, setShowFilters] = useState(true);
  const [historyPushed, setHistoryPushed] = useState(false);
  // Set default true for the case when someone F5
  const [requestFilters, setRequestFilters] = useState(true);
  const customDisplay = {
    display: showFilters ? 'block' : 'none',
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);



  // filters logic
  let emptyFilters: IFilter[] = [
    {
      nombre: '',
      min: 0,
      max: 0,
      enabled: false,
      tipo: '',
      valor: 0,
      valores: [{ nombre: '', codigo: '', enabled: false, valor: false }],
      mode: '',
      codigo: '',
    },
  ];
  const [filters, setFilters] = useState(
    props.filters && props.filters.length > 0 ? props.filters : emptyFilters,
  );

  let emptyRol: IRol = {
    asignaturas: [],
    carreras: [],
    coordinador: false,
    director: false,
    docente: false,
    itrs: [],
    rol: '',
    rol_id: 0,
    rol_type: '',
  };
  const [rol, setRol] = useState(emptyRol);
  const disableFilters = filters?.filter((x) => x.enabled).length === 0;
  const [isList, setIsList] = useState(true);

  props.updateFiltersVisibility(!disableFilters);

  useEffect(() => {
    history.listen((location: any, action: any) => {
      if (action !== 'REPLACE' && !historyPushed) {
        setHistoryPushed(true);
        setRequestFilters(true);
      }
      setIsList(location.pathname.split('/').slice(-1)[0] === 'listado');
    });
  }, [history, props.filters]);

  
  function  getFilterValuesForRequest(filters: IFilter[]) {
    if (!filters) return {};
  
    return filters.length > 0 && filters[0].nombre === '' ? {} : filters;
  }

  useEffect(() => {
    async function fetchFilters() {
      const currentURL = window.location.pathname;
      const id = new URLSearchParams(window.location.search).get('id') || '';

      const filtersResponse: IInfo = await InfoService.getFilters(
        getFilterValuesForRequest(props.filters),
        id,
        currentURL,
      );

      setRol(filtersResponse.rol);
      let newFilters = resetAndBuild(rol, filtersResponse.filters);
      setFilters(newFilters);

      if (
        localStorage.getItem('filterSet') !== '1' &&
        props.filters?.length === undefined &&
        newFilters[0]?.codigo !== ''
      ) {
        localStorage.setItem('filterSet', '1');
        props.updateFilters(newFilters);
      } else {
        props.updateFilters(newFilters);
      }
    }

    if (requestFilters) {
      fetchFilters();
      setRequestFilters(false);
    }
  }, [props.filters, requestFilters]);

  /**
   * Sets filter hooks using redux values if any
   * @param filter
   * @param redux
   */
  const setFilterValue = (filter: IFilter) => {
    switch (filter.codigo) {
      case CONST_YEAR:
        {
          setSelectedYear(filter.valor || 0);
        }
        break;
      case CONST_ESTUDIANTE:
        {
          setSelectedStudent(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_ITR:
        {
          setSelectedITR(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_CARRERAS:
        {
          setSelectedMajors(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_PERIODO:
        {
          setSelectedPeriods(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_CURSOS:
        {
          setSelectedCourses(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_SEMESTRE:
        {
          setSelectedSemester(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_ESTADO:
        {
          setSelectedState(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_ASIGNATURA:
        {
          setSelectedAsignaturas(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
      case CONST_SEDE:
        {
          setSelectedCampus(
            getNamesListFromEnabledAndSelectedValues(filter.valores),
          );
        }
        break;
    }
  };

  /**
   * Gets the
   * @param array array of [[IValue]] elements
   * @returns
   */
  const getNamesListFromEnabledAndSelectedValues = (
    array: IValue[] | undefined,
  ) => {
    return (
      array
        ?.filter((val: any) => val.enabled && val.valor)
        .map(({ nombre }: any) => nombre) || ['']
    );
  };

  /**
   * Creates filters from the API response.
   *
   * @param rol users rol, retrieved from any API call
   * @param filtros filters retrieved by the API call
   */
  const resetAndBuild = (rol: IRol, filtros: IFilter[]): IFilter[] => {
    filters.forEach((filter: IFilter, index: number) => {
      setFilterValue(filter);

      // check main checkbox
      const enabledRows =
        filter.valores?.filter((value) => {
          return (
            value.nombre.toUpperCase() !== CONST_TODAS &&
            value.nombre.toUpperCase() !== CONST_TODOS &&
            value.enabled
          );
        }).length || 0;

      const filterValuesList = getFilterValuesList(filter.codigo);
      const checkedRows =
        filter.valores?.filter((value) => {
          return (
            value.nombre.toUpperCase() !== CONST_TODAS &&
            value.nombre.toUpperCase() !== CONST_TODOS &&
            value.enabled &&
            filterValuesList.includes(value.nombre)
          );
        }).length || 0;

      if (checkedRows >= enabledRows) {
        checkUncheckAllCheckboxes(true, index);
      }
    });

    // make sure teachers dont have everything unchecked
    if (rol.docente && !rol.coordinador) {
      filtros.forEach((f: IFilter) => {
        if (f.codigo === 'asignaturas' && f.valores) {
          let noneChecked = true;
          f.valores.forEach((item: any) => {
            if (item.valor) {
              noneChecked = false;
            }
          });
          if (noneChecked) {
            // check all
            f.valores.forEach((item: any) => {
              item.valor = true;
            });
          }
        }
      });
    }

    return filtros;
  };

  const getFilterValuesList = (valueName: string) => {
    if (valueName === CONST_ITR) {
      return selectedITR;
    } else if (valueName === CONST_CARRERAS) {
      return selectedMajors;
    } else if (valueName === CONST_PERIODO) {
      return selectedPeriods;
    } else if (valueName === CONST_CURSOS) {
      return selectedCourses;
    } else if (valueName === CONST_SEMESTRE) {
      return selectedSemester;
    } else if (valueName === CONST_ESTADO) {
      return selectedState;
    } else if (valueName === CONST_ASIGNATURA) {
      return selectedAsignaturas;
    } else if (valueName === CONST_SEDE) {
      return selectedCampus;
    } else {
      return [''];
    }
  };

  const checkUncheckMainCheckbox = () => {
    filters.forEach((filter) => {
      const enabledRows =
        filter.valores?.filter((value) => {
          return (
            value.nombre.toUpperCase() !== CONST_TODAS &&
            value.nombre.toUpperCase() !== CONST_TODOS &&
            value.enabled
          );
        }).length || 0;

      const filterValuesList = getFilterValuesList(filter.codigo);
      const checkedRows =
        filter.valores?.filter((value) => {
          return (
            value.nombre.toUpperCase() !== CONST_TODAS &&
            value.nombre.toUpperCase() !== CONST_TODOS &&
            value.enabled &&
            filterValuesList.includes(value.nombre)
          );
        }).length || 0;

      const allLabel =
        filter.valores?.find((value) => {
          return (
            value.nombre.toUpperCase() === CONST_TODOS ||
            value.nombre.toUpperCase() === CONST_TODAS
          );
        })?.nombre || '';
      if (checkedRows >= enabledRows) {
        if (filter.codigo === CONST_ESTUDIANTE) {
          setSelectedStudent([...selectedStudent, allLabel]);
        } else if (filter.codigo === CONST_ITR) {
          setSelectedITR([...selectedITR, allLabel]);
        } else if (filter.codigo === CONST_CARRERAS) {
          setSelectedMajors([...selectedMajors, allLabel]);
        } else if (filter.codigo === CONST_PERIODO) {
          setSelectedPeriods([...selectedPeriods, allLabel]);
        } else if (filter.codigo === CONST_CURSOS) {
          setSelectedCourses([...selectedCourses, allLabel]);
        } else if (filter.codigo === CONST_SEMESTRE) {
          setSelectedSemester([...selectedSemester, allLabel]);
        } else if (filter.codigo === CONST_ESTADO) {
          setSelectedState([...selectedState, allLabel]);
        } else if (filter.codigo === CONST_ASIGNATURA) {
          setSelectedAsignaturas([...selectedAsignaturas, allLabel]);
        } else if (filter.codigo === CONST_SEDE) {
          setSelectedCampus([...selectedCampus, allLabel]);
        }
      } else {
        if (filter.codigo === CONST_ESTUDIANTE) {
          setSelectedStudent(
            selectedStudent.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_ITR) {
          setSelectedITR(
            selectedITR.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_CARRERAS) {
          setSelectedMajors(
            selectedMajors.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_PERIODO) {
          setSelectedPeriods(
            selectedPeriods.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_CURSOS) {
          setSelectedCourses(
            selectedCourses.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_SEMESTRE) {
          setSelectedSemester(
            selectedSemester.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_SEDE) {
          setSelectedCampus(
            selectedCampus.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_ESTADO) {
          setSelectedState(
            selectedState.filter((item: string) => item !== allLabel),
          );
        } else if (filter.codigo === CONST_ASIGNATURA) {
          setSelectedAsignaturas(
            selectedState.filter((item: string) => item !== allLabel),
          );
        }
      }
    });
  };

  const checkUncheckAllCheckboxes = (checked: boolean, index: number) => {
    if (filters[index]) {
      if (checked) {
        const standarizedSearch = standarizeString(majorsFilter);

        const allLabels = filters[index].valores
          ?.filter((value) => {
            return (
              value.enabled &&
              (majorsFilter?.length === 0 ||
                standarizeString(value.nombre).includes(standarizedSearch))
            );
          })
          .map(({ nombre }) => nombre);

        if (filters[index].codigo === CONST_ESTUDIANTE) {
          setSelectedStudent([...(allLabels || selectedStudent)]);
        } else if (filters[index].codigo === CONST_ITR) {
          setSelectedITR([...(allLabels || selectedITR)]);
        } else if (filters[index].codigo === CONST_CARRERAS) {
          setSelectedMajors([...(allLabels || selectedMajors)]);
        } else if (filters[index].codigo === CONST_PERIODO) {
          setSelectedPeriods([...(allLabels || selectedPeriods)]);
        } else if (filters[index].codigo === CONST_CURSOS) {
          setSelectedCourses([...(allLabels || selectedCourses)]);
        } else if (filters[index].codigo === CONST_SEMESTRE) {
          setSelectedSemester([...(allLabels || selectedSemester)]);
        } else if (filters[index].codigo === CONST_ESTADO) {
          setSelectedState([...(allLabels || selectedState)]);
        } else if (filters[index].codigo === CONST_ASIGNATURA) {
          setSelectedAsignaturas([...(allLabels || selectedAsignaturas)]);
        } else if (filters[index].codigo === CONST_SEDE) {
          setSelectedCampus([...(allLabels || selectedCampus)]);
        }
      } else {
        if (filters[index].codigo === CONST_ESTUDIANTE) {
          setSelectedStudent(['']);
        } else if (filters[index].codigo === CONST_ITR) {
          setSelectedITR(['']);
        } else if (filters[index].codigo === CONST_CARRERAS) {
          setSelectedMajors(['']);
        } else if (filters[index].codigo === CONST_PERIODO) {
          setSelectedPeriods(['']);
        } else if (filters[index].codigo === CONST_CURSOS) {
          setSelectedCourses(['']);
        } else if (filters[index].codigo === CONST_SEMESTRE) {
          setSelectedSemester(['']);
        } else if (filters[index].codigo === CONST_ESTADO) {
          setSelectedState(['']);
        } else if (filters[index].codigo === CONST_ASIGNATURA) {
          setSelectedAsignaturas(['']);
        } else if (filters[index].codigo === CONST_SEDE) {
          setSelectedCampus(['']);
        }
      }
    }
  };

  /**
   *
   * @param start start number
   * @param stop last number
   * @param step iteration amount
   * @returns returns an array from `start` to `stop`, skipping `step` on each iteration
   */
  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );

  const updateSelectedFiltersOnRedux = () => {
    checkUncheckMainCheckbox();
    let emptyBlock = false;
    let isITRSelected = false;
    let isCarrerasSelected = false;


    const newFilters = filters.map((filter) => {
      const newFilter = { ...filter };
      let isAnyFilterSelected = false;

      switch (filter.codigo) {
        case CONST_YEAR:
          newFilter.valor = selectedYear;
          isAnyFilterSelected = true;
          break;

        case CONST_ESTUDIANTE:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedStudent.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        case CONST_ITR:
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedITR.includes(value.nombre);
            isITRSelected = isITRSelected || selected;
            isAnyFilterSelected = isAnyFilterSelected || selected;
            return { ...value, valor: selected };
          });
          break;

        case CONST_CARRERAS:
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedMajors.includes(value.nombre);
            isCarrerasSelected = isCarrerasSelected || selected;
            isAnyFilterSelected = isAnyFilterSelected || selected;
            return { ...value, valor: selected };
          });
          break;

        case CONST_PERIODO:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedPeriods.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        case CONST_CURSOS:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedCourses.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        case CONST_SEMESTRE:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedSemester.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        case CONST_ESTADO:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedState.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;
        case CONST_ASIGNATURA:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedAsignaturas.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        case CONST_SEDE:
          isAnyFilterSelected = true;
          newFilter.valores = filter.valores?.map((value) => {
            const selected = selectedCampus.includes(value.nombre);
            return { ...value, valor: selected };
          });
          break;

        default:
          isAnyFilterSelected = true;
          break;
      }

      if (!isAnyFilterSelected) {
        emptyBlock = true;
      }

      return newFilter;
    });

    if (emptyBlock && isList && !isITRSelected) {

      setOpen(true);
    } else {
      setFilters(newFilters);
      props.updateFilters(newFilters);
    }
  };

  if (disableFilters) {
    return null;
  }
  return (
    <>
      <Root>
        <Button
          variant="contained"
          startIcon={<FilterAltIcon />}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ background: '#007bff' }}
        >
          {!showFilters ? 'Abrir filtros' : 'Cerrar filtros'}
        </Button>
      </Root>
      <Box sx={customDisplay}>
        <Grow in={showFilters}>
          <div className={classes.filterDiv}>
            <FormGroup>
              <FormLabel component="legend" className={classes.title}>
                Filtrar por:
              </FormLabel>
            </FormGroup>

            <hr className={classes.hrFilter} />

            <Button
              disabled={disableFilters}
              variant="contained"
              className={classes.buttonApplyFilters}
              onClick={updateSelectedFiltersOnRedux}
            >
              Aplicar filtros
            </Button>

            <hr className={classes.hrFilter} />

            {filters.map((filter, index: number) => {
              if (filter.enabled) {
                if (filter.tipo === 'slider') {
                  return (
                    <div key={filter.nombre}>
                      <FormGroup>
                        <YearPicker
                          yearsArray={range(
                            filter.min || selectedYear,
                            filter.max || selectedYear,
                            1,
                          )}
                          selectedYear={selectedYear}
                          setSelectedYear={setSelectedYear}
                        >
                          <FormLabel
                            component="legend"
                            className={classes.subTitle}
                          >
                            {filter.nombre}
                          </FormLabel>
                        </YearPicker>
                      </FormGroup>
                      <hr className={classes.hrFilter} />
                    </div>
                  );
                } else if (filter.tipo === 'checkboxes') {
                  const checkboxControlArray: ICheckboxControl[] =
                    filter.valores?.map<ICheckboxControl>((item) => {
                      return {
                        label: item.nombre,
                        description: item.descripcion || '',
                        enabled: item.enabled,
                        prob_abandono: item.prob_abandono || '',
                      };
                    }) || [
                      {
                        label: '',
                        description: '',
                        enabled: false,
                        prob_abandono: '',
                      },
                    ];

                  if (filter.codigo === CONST_ITR) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedITR}
                            setSelectedItems={setSelectedITR}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_ESTUDIANTE) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedStudent}
                            setSelectedItems={setSelectedStudent}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                            useScroll
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        {/* <FormGroup>
                          <FormLabel
                            component="legend"
                            className={classes.subTitle}
                          >
                            Referencias: ddd
                          </FormLabel>
                          <Typography variant="body1" display="block">
                            Probabilidad de abandono
                          </Typography>

                          <Box
                            className={classes.probabilityOfQuittingReferences}
                          >
                            <Box
                              className={
                                classes.probabilityOfQuittingReferences
                              }
                            >
                              <CircleIcon style={{ color: '#F2C12A' }} />
                              <Typography>Medio</Typography>
                            </Box>
                            <Box
                              className={
                                classes.probabilityOfQuittingReferences
                              }
                            >
                              <CircleIcon style={{ color: '#EE7E27' }} />
                              <Typography>Alto</Typography>
                            </Box>
                            <Box
                              className={
                                classes.probabilityOfQuittingReferences
                              }
                            >
                              <CircleIcon style={{ color: '#F01B27' }} />
                              <Typography>Muy Alto</Typography>
                            </Box>
                          </Box>
                        </FormGroup> */}
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_CARRERAS) {
                    const standarizedSearch = standarizeString(majorsFilter);
                    const filtered =
                      majorsFilter?.length > 0
                        ? checkboxControlArray.filter((item) => {
                          return (
                            standarizeString(item.label).includes(
                              standarizedSearch,
                            ) ||
                              item.label.toUpperCase() === CONST_TODAS ||
                              item.label.toUpperCase() === CONST_TODOS
                          );
                        })
                        : checkboxControlArray;

                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={filtered}
                            selectedItems={selectedMajors}
                            setSelectedItems={setSelectedMajors}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                            useScroll
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>

                            <input
                              type="text"
                              className={classes.searchMajor}
                              placeholder="Buscar carrera..."
                              value={majorsFilter}
                              onInput={(e: any) =>
                                setMajorsFilter(e.target.value)
                              }
                            />
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_PERIODO) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedPeriods}
                            setSelectedItems={setSelectedPeriods}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_CURSOS) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedCourses}
                            setSelectedItems={setSelectedCourses}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                            useScroll
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_SEMESTRE) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedSemester}
                            setSelectedItems={setSelectedSemester}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              Período
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_ESTADO) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedState}
                            setSelectedItems={setSelectedState}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_ASIGNATURA) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedAsignaturas}
                            setSelectedItems={setSelectedAsignaturas}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  } else if (filter.codigo === CONST_SEDE) {
                    return (
                      <div key={filter.nombre}>
                        <FormGroup>
                          <CheckboxControlList
                            checkboxArray={checkboxControlArray}
                            selectedItems={selectedCampus}
                            setSelectedItems={setSelectedCampus}
                            setSelectedItemsCallback={checkUncheckAllCheckboxes}
                            listIndex={index}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.subTitle}
                            >
                              {filter.nombre}
                            </FormLabel>
                          </CheckboxControlList>
                        </FormGroup>
                        <hr className={classes.hrFilter} />
                      </div>
                    );
                  }
                }
              }
            })}

            <Button
              disabled={disableFilters}
              variant="contained"
              className={classes.buttonApplyFilters}
              onClick={updateSelectedFiltersOnRedux}
            >
              Aplicar filtros
            </Button>
          </div>
        </Grow>
      </Box>
      {open ? (
        <BasicModalNoButton
          title="Debe seleccionar al menos un ITR y una carrera"
          description=""
          isOpen={open}
          closeFunction={handleClose}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return { filters: state.filters };
};

export default connect(mapStateToProps, {
  updateFilters,
  updateFiltersVisibility,
})(Filters);
