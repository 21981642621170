import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
} from '@mui/material';

import { IUser } from 'services/user/user.interface';
import UserService from 'services/user/user.service';
import { saveUserLogIn } from 'store/actions/userInfo/userAction';
import useStyles from './LogInForm.styles';
import UserProfileService from 'services/userProfile/userProfile.service';

enum InputType {
  USERNAME = 0,
  PASSWORD = 1,
}

/**
 * Component for login form inputs and buttons
 *
 * @component
 */
const LogInForm = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [inputsData, setInputsData] = useState([
    { id: 'username', error: false, helperText: '' },
    { id: 'password', error: false, helperText: '' },
  ]);

  /**
   * handle submit and, if no errors are found, submit
   */
  const onSubmitHandle = (event: any) => {
    event.preventDefault();
    if (validateForm()) {
      executeSubmit();
    }
  };

  /**
   * validate user input
   * @returns returns true if username and password are given
   */
  const validateForm = () => {
    let hasError = false;
    setInputsData((previous) => {
      const temp = [...previous];
      temp[InputType.USERNAME].error = false;
      temp[InputType.USERNAME].helperText = '';
      temp[InputType.PASSWORD].error = false;
      temp[InputType.PASSWORD].helperText = '';

      return temp;
    });

    if (username.length === 0) {
      hasError = true;
      setInputsData((previous) => {
        const temp = [...previous];
        temp[InputType.USERNAME].error = true;
        temp[InputType.USERNAME].helperText = 'Ingrese un usuario valido.';

        return temp;
      });
    }
    if (password.length === 0) {
      hasError = true;
      setInputsData((previous) => {
        const temp = [...previous];
        temp[InputType.PASSWORD].error = true;
        temp[InputType.PASSWORD].helperText = 'Ingrese una contraseña valida.';

        return temp;
      });
    }

    return !hasError;
  };

  /**
   * submits form and redirects to dashboard screen
   */

  const [userData, setUserData] = useState<any>(null); 
  
  const executeSubmit = async () => {
    const user: IUser = {
      username: username,
      password: password,
      remember: remember,
      loggedIn: true,
    };
  
    try {
      setIsLoading(true);
      const authenticatedUser = await UserService.sendLogin(user);
  
      if (authenticatedUser.accessToken === '' && authenticatedUser.id === '') {
        setIsLoading(false);
        setUsername('');
        setPassword('');
        setInputsData((previous) => {
          const temp = [...previous];
          temp[InputType.USERNAME].error = true;
          temp[InputType.USERNAME].helperText = 'Ingrese un usuario valido.';
          return temp;
        });
        return;
      }
  
     await UserService.setLogin(user, authenticatedUser.accessToken);
      props.saveUserLogIn(user);
      setUserData(user); 
    } catch (e) {
      setIsLoading(false);
    }
  };
  const fetchUserData = async (user: IUser) => {
    try {
      const result: any = await UserProfileService.getStudentDataDetails(user.username);
      return result; 
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null; 
    }
  };
  
  useEffect(() => {
    const fetchDataAndRedirect = async () => {
      try {
        const userDataFromStorage = localStorage.getItem('data');        
        if (userDataFromStorage) {
          const userDataObject = JSON.parse(userDataFromStorage);
          
          const userRole = userDataObject.rol;
          
          if (userRole === 'Estudiante') {
            const fetchedData = await fetchUserData(userDataObject);
            
            history.push(`v1.1/navegacion/estudiantes/estudiante?id=${fetchedData.headerProfile.matriculacion_id}&plan=${fetchedData.headerProfile.plan_id}`);
          } else {
            history.push('v1.1/navegacion/inicio/graficas');
          }
        } else {
          console.log('No user data found in storage.');
        }
      } catch (error) {
        console.error('Error fetching and redirecting:', error);
      }
    };
  
    fetchDataAndRedirect();
  }, [history, userData]);
  
  

  return (
    <Box
      sx={{ margin: '10px 0px' }}
      component="form"
      onSubmit={onSubmitHandle}
      autoComplete="on"
      noValidate
    >
      <TextField
        sx={{ margin: '5px 0px' }}
        id="outlined-required"
        label="Usuario"
        type="text"
        autoComplete="current-user"
        fullWidth
        value={username}
        onChange={(event) => {
          setUsername(event.target.value);
        }}
        error={inputsData[InputType.USERNAME].error}
        helperText={inputsData[InputType.USERNAME].helperText}
        disabled={isLoading}
      />
      <TextField
        sx={{ margin: '5px 0px' }}
        id="outlined-password-input"
        label="Contraseña"
        type="password"
        autoComplete="current-password"
        fullWidth
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
        error={inputsData[InputType.PASSWORD].error}
        helperText={inputsData[InputType.PASSWORD].helperText}
        disabled={isLoading}
      />

      <FormGroup sx={{ alignContent: 'center' }}>
        <FormControlLabel
          control={<Checkbox />}
          label="Recordar contraseña"
          checked={remember}
          onChange={() => setRemember(!remember)}
          disabled={isLoading}
        />
      </FormGroup>

      <Box sx={{ margin: '10px 0px' }}>
        <Button
          className={classes.submitButton}
          type="submit"
          disabled={isLoading}
        >
          Ingresar
        </Button>
        <Link
          className={classes.forgottenPassword}
          underline="hover"
          color="inherit"
          href="https://pwm.utec.edu.uy/pwm/public/forgottenpassword"
        >
          Contraseña olvidada
        </Link>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return { userInfo: state.userInfo };
};

export default connect(mapStateToProps, { saveUserLogIn })(LogInForm);
