import React from 'react';

import { CircularProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import LineChart from 'components/charts/Line/LineChart';
import BarChart from 'components/charts/Bar/BarChart';
import { IChart } from './Charts.interface';
import useStyles from './Charts.styles';
import HorizontalBarChart from './HorizontalBar/HorizontalBarChart';
import TableChart from './Table/TableChart';


const backgroundColor = [
  'rgb(146, 174, 131)',
  'rgb(36, 123, 160)',
  'rgb(242, 95, 92)',
  'rgb(249, 160, 97)',
  'rgb(255, 224, 102)',
  'rgb(80, 81, 79)',
  'rgb(161, 88, 86)',
  'rgb(74, 158, 170)',
  'rgb(93, 176, 175)',
  'rgb(112, 193, 179)',
  'rgb(0, 48, 73)',
  'rgb(107, 44, 57)',
  'rgb(214, 40, 40)',
  'rgb(231, 84, 20)',
  'rgb(247, 127, 0)',
  'rgb(250, 159, 37)',
  'rgb(252, 191, 73)',
  'rgb(243, 209, 128)',
  'rgb(239, 218, 156)',
  'rgb(234, 226, 183)',
  'rgb(36, 123, 160)',
  'rgb(74, 158, 170)',
  'rgb(112, 193, 179)',
  'rgb(145, 206, 185)',
  'rgb(178, 219, 191)',
  'rgb(211, 237, 190)',
  'rgb(243, 255, 189)',
  'rgb(249, 139, 137)',
  'rgb(252, 81, 111)',
  'rgb(255, 22, 84)',
  'rgb(43, 45, 66)',
  'rgb(92, 99, 120)',
  'rgb(141, 153, 174)',
  'rgb(189, 198, 209)',
  'rgb(237, 242, 244)',
  'rgb(238, 139, 152)',
  'rgb(239, 35, 60)',
  'rgb(228, 20, 51)',
  'rgb(223, 12, 46)',
  'rgb(217, 4, 41)',
];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

/**
 * General component used to render [[BarChart]] and [[LineChart]] charts
 *
 * @component
 */
const Chart = (props: IChart) => {
  const classes = useStyles();
  const { chartType, data, isLoading } = props;

  if (isLoading || (!data || !props.data)) {
    return (
      <Item className={classes.chartGridBox}>
        <CircularProgress />
      </Item>
    );
  } else {
    // add color to each item
    props.data.datasets = data.datasets?.map((dataset, index) => {
      return {
        ...dataset,
        backgroundColor: backgroundColor[index],
        borderRadius: 10,
        borderSkipped: false,
      };
    });

    switch (chartType) {
      case 'Line':
        return (
          <Item className={classes.chartGridBox}>
            <LineChart {...props} />
          </Item>
        );
      case 'HorizontalBar':
        return (
          <Item className={classes.chartGridBox}>
            <HorizontalBarChart {...props} />
          </Item>
        );
      case 'Table':
        return (
          <Item className={classes.chartGridBox}>
            <TableChart {...props} />
          </Item>
        );
      case 'Bar':
      default:
        return (
          <Item className={classes.chartGridBox}>
            <BarChart {...props} />
          </Item>
        );
    }
  }
};

export default Chart;
