import React from 'react';

import { Chip } from '@mui/material';
import { Box } from '@mui/system';

import { IIconTag } from './IconTag.interface';

/**
 * Component to set IconTag on the different views
 *
 * /** Displays a tag with an Icon
 *
 * @component
 */
const IconTag = ({ label, color, icon, bg, onClick }: IIconTag) => {
  return (
    <Box>
      <Chip
        label={label}
        icon={icon}
        sx={{
          backgroundColor: `${bg}`,
          color: `${color}`,
          borderRadius: '8px',
          marginRight: '5px',
          border: `1px solid ${color}`,
        }}
        onClick={onClick}
      />
    </Box>
  );
};

export default IconTag;
