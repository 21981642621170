import React from 'react';
import { useHistory } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip, IconButton, Link, Grid } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';

import { generateAndDownloadCsvWithTimeStamp } from 'utils/helper';
import { IChart } from '../Charts.interface';
import useStyles from '../Charts.styles';

/**
 * Component used to render Horizontal Bar charts
 * Used by component: [[Chart]]
 *
 * @component
 */
const HorizontalBarChart = ({
  name,
  description,
  seeMoreUrl,
  data,
}: IChart) => {
  const classes = useStyles();
  const history = useHistory();

  if (data) {
    const chartName = name ?? 'Bar Chart';
    const tooltip = description || '';

    const chartOptions: ChartOptions = {
      locale: 'es-ES',
      indexAxis: 'y',
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Tooltip className={classes.infoIcon} title={tooltip}>
              <IconButton>
                <InfoIcon sx={{ color: '#82858E' }} />
              </IconButton>
            </Tooltip>
            <span className={classes.chartTitle}>{chartName}</span>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            style={{ justifyContent: 'right' }}
          >
            {seeMoreUrl && (
              <div
                style={{
                  border: '1px solid #EAEEF2',
                  borderRadius: '12px',
                  padding: '12px 16px',
                  marginRight: '10px',
                }}
              >
                <Link
                  className={classes.seeMore}
                  onClick={() => {
                    history.push(seeMoreUrl);
                  }}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Ver más
                </Link>
              </div>
            )}
            <div
              style={{
                border: '1px solid #EAEEF2',
                borderRadius: '12px',
              }}
            >
              <Tooltip title="Descargar csv">
                <IconButton
                  onClick={() =>
                    generateAndDownloadCsvWithTimeStamp(
                      chartName,
                      data.labels,
                      data.datasets,
                    )
                  }
                >
                  <DownloadIcon sx={{ color: '#007BFF' }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <hr />
        <Bar options={chartOptions} data={data} />
      </>
    );
  } else {
    return null;
  }
};

export default HorizontalBarChart;
