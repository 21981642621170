import React from 'react';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';

import { IRoutes } from './CustomBreadcrumbs.interface';
import useStyles from './CustomBreadcrumbs.styles';

const CustomBreadcrumbs = ({ crumbs }: any) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className={classes.container}
    >
      <Link
        color="inherit"
        onClick={() => {
          history.push('/v1.1/navegacion/inicio/graficas');
        }}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Inicio
      </Link>

      {crumbs?.map((crumb: IRoutes, index: number) => {
        if (crumb.hide) {
          return null;
        }

        const isLast = index === crumbs.length - 1;

        if (isLast) {
          return (
            <Typography key={crumb.alias} color="text.primary">
              {crumb.alias}
            </Typography>
          );
        }

        const url = crumb.default
          ? crumb.path
          : crumb.children?.find((x) => x.default)?.path || '';

        return (
          <Link
            underline="hover"
            color="inherit"
            key={crumb.alias}
            onClick={() => {
              history.push(url);
            }}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            {crumb.alias}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
