import { makeStyles } from '@mui/styles';

export default makeStyles({
  headerRow: {
    background: '#EAEEF2',
  },
  headerText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#777D92',
  },
  oddRow: {
    backgroundColor: '#F9FAFB',
  },
  evenRow: {
    backgroundColor: '#FFFFFF',
  },
  rowText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    color: '#31374C',
  },
  linkText: {
    cursor: 'pointer',
    textDecoration: 'underline',
  }
});
