import axios, { ldapAxios } from 'utils/axios';
import { IUser } from './user.interface';

interface IAuthenticatedUser {
  accessToken: string;
  id: string;
}

/**
 * Service for user related operations
 */
const UserService = {
  /**
   *
   * @param token user token
   * @returns returns links for the NavBar
   */
  getNavbarMenu: async (token: string) => {
    const response: any = await axios.post(
      `/v1.1/navegacion/home?access_token=${token}`,
    );

    let links = response.data.menu;

    try {
      const homeScreenIndex = links.indexOf(
        links.find((a: any) => a.nombre === 'Inicio'),
      );

      links.splice(homeScreenIndex, 1, {
        nombre: 'AA',
        link: '/v1.1/navegacion/inicio/graficas',
        enabled: true,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}

    links = links.concat([
      { nombre: 'Contacto', link: '/contacto', enabled: true },
      { nombre: 'Ayuda', link: '/ayuda', enabled: true },
    ]);
    return links;
  },

  sendLogin: async (user: IUser): Promise<IAuthenticatedUser> => {
    try {
      const userInfo: any = await ldapAxios.post('/auth/ldap', {
        username: user.username,
        password: user.password,
      });
      

      return {
        accessToken: userInfo.data.access_token,
        id: userInfo.data.id,
      };
    } catch (e) {
      return { accessToken: '', id: '' };
    }
  },

  sendLogout: () => {
    localStorage.removeItem('user_aa');
    localStorage.removeItem('token');
    localStorage.removeItem('data');
    localStorage.removeItem('filterSet');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_aa');
    sessionStorage.removeItem('data');
  },

  setLogin: async (user: IUser, accessToken: string) => {
    sessionStorage.setItem('user_aa', user.username);
    sessionStorage.setItem('token', accessToken);
    if (user) {
      localStorage.setItem('user_aa', user.username);
      localStorage.setItem('token', accessToken);
    } else {
      localStorage.removeItem('user_aa');
    }

    const loggedUserInfo: any = await axios.get(
      `/me?access_token=${accessToken}`,
    );

    const dataUser = {
      nombre: '',
      email: '',
      carrera: '',
      docente: false,
      centro: '',
      rol: '',
      username: '',
    };

    dataUser.nombre = loggedUserInfo.data['nombre'];
    dataUser.email = loggedUserInfo.data['email'];
    dataUser.carrera = loggedUserInfo.data['carrera'];
    dataUser.docente = loggedUserInfo.data['docente'];
    dataUser.centro = loggedUserInfo.data['centro'];
    dataUser.rol = loggedUserInfo.data['rol'];
    dataUser.username = user.username;
    sessionStorage.setItem('data', JSON.stringify(dataUser));

    if (user) {
      localStorage.setItem('data', JSON.stringify(dataUser));
    } else {
      localStorage.removeItem('data');
    }
  },

  getSessionUser: () => {
    const sessionUser = sessionStorage.getItem('data');

    if (sessionUser) {
      return JSON.parse(sessionUser);
    }

    return {};
  },
};

/**
 *
 * @returns user token from localStorage
 */
export const getToken = (): string => {
  if (sessionStorage.getItem('token')) {
    return sessionStorage.getItem('token') || '';
  }

  return '';
};

export default UserService;