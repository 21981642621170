import React from 'react';

import { Grid, Paper, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/system';

import useStyles from './ActivityByCareer.style';

import ActivityTableBody from './ActivityTableBody/ActivityTableBody';
import { IPaperBoxWithIcon } from 'components/rootComponents/Standards/PaperBoxWithIcon/PaperBoxWithIcon.interface';
import { IActivityByCareerData } from './ActiviryByCareer.interface';
import PaperBoxWithIcon from 'components/rootComponents/Standards/PaperBoxWithIcon/PaperBoxWithIcon';
import ViewTitle from 'components/rootComponents/Standards/ViewTitle/ViewTitle';

/**
 * component that renders the amount of data by subjects that you
 * have in the course, also has implemented
 * a table showing the tasks filtered by year and semester.
 * @component
 */
const ActivityByCareer = ({ data }: IActivityByCareerData) => {
  const style = useStyles();

  const educationalActivity: IPaperBoxWithIcon[] = [
    // {
    //   title: 'Probabilidad de abandono',
    //   data: data.activity.probabilidad_abandono || 'Sin Datos',
    //   icon: <ReplyIcon sx={{ color: '#007BFF' }} />,
    // },
    {
      title: 'Creditos Obtenidos',
      data: data.activity.creditos_obtenidos || 'Sin Datos',
      icon: <ListAltIcon sx={{ color: '#007BFF' }} />,
    },
    {
      title: 'Materias Matriculadas',
      data: data.activity.materias_matriculadas || 'Sin Datos',
      icon: <BookmarkAddIcon sx={{ color: '#007BFF' }} />,
    },
    {
      title: 'Materias aprobadas',
      data: data?.activity.materias_aprobadas || 'Sin Datos',
      icon: <BookmarkAddedIcon sx={{ color: '#007BFF' }} />,
    },
  ];

  /**
   * Board subjects Passed with failed subjects
   */
  const materialData = data?.materiaData?.aproved?.concat(
    data?.materiaData?.disapproved,
  );

  /**
   * creates a new array with the years taken by the student
   */
  let years = materialData?.map((material: any) => {
    return material.year.slice(0, 4);
  });

  /**
   * Create a new SET so as not to have repeated years
   */
  let yearSet = Array.from(new Set(years));
  /**
   * Filter by year and semester and sort them so that you can then use the result and display
   * it in the component that renders that information.
   */
  let filteredYears = yearSet.map((year: any) => {
    return {
      year: year,
      data: {
        S2: materialData.filter((material: any) => {
          return (
            material.year.slice(0, 4) === year &&
            material.year.slice(5, 7) === '2S'
          );
        }),
        S1: materialData.filter((material: any) => {
          return (
            material.year.slice(0, 4) === year &&
            material.year.slice(5, 7) === '1S'
          );
        }),
      },
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={style.title}>
          <ViewTitle
            title={`Actividad por carrera: ${data?.activity?.carrera} `}
          />
        </Box>
      </Grid>
      {/*Paper Data */}
      {educationalActivity &&
        educationalActivity.map((paper: any, index: any) => {
          return (
            <Grid item xs={6} md={3} key={index}>
              <PaperBoxWithIcon
                title={paper.title}
                data={paper.data}
                icon={paper.icon}
              />
            </Grid>
          );
        })}

      {/*Table */}
      <Grid item xs={12}>
        <Paper sx={{ width: '100%', mb: 2, borderRadius: '12px' }}>
          <Box className={style.headerRow}>
            <Box className={style.headerText}>
              Resumen de la matriculacion a UC
            </Box>
            <Box className={style.headerIcons}>
              <Box className={style.headerIcons}>
                <CircleIcon className={style.colorAprovedd} />
                <Typography className={style.headerText}>Aprobadas</Typography>
              </Box>
              <Box className={style.headerIcons}>
                <CircleIcon className={style.colorDisapproved} />
                <Typography className={style.headerText}>
                  Desaprobadas
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={style.table}>
            {filteredYears?.map((career, key) => {
              return <ActivityTableBody data={career} key={key} />;
            })}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ActivityByCareer;
