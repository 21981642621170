/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'utils/axios';
import { ChartType, IChart } from 'components/charts/Charts.interface';
import { getToken } from 'services/user/user.service';
import { IFilter } from 'services/info/info.interface';

/**
 * Service for [[Chart]]
 */
const ChartService = {
  /**
   * @param token user's token
   * @returns graph data ready to render
   */
  getChartsLinks: async (
    url: string,
    filters: IFilter[],
    id = '',
    abortController: any,
  ) => {
    const token = getToken();
    let queryString = `?access_token=${token}`;
    if (id && id.length > 0) {
      queryString += `&id=${id}`;
    }

    const response: any = await axios.post(`${url}${queryString}`, filters, {
      signal: abortController,
    });
    const links = response.data.contenido.__data;
    const metaTitle = response.data.contenido.__meta.title;
    return { links, metaTitle };
  },

  /**
   * @param url API endpoint to retrieve Chart data
   * @param token User authenticated token
   * @returns Chart data to render
   */
  getChart: async (url: string, filters: IFilter[]): Promise<IChart> => {
    const token = getToken();
    let queryString = `?access_token=${token}`;
    if (url.includes('id=')) {
      queryString = `&access_token=${token}`;
    }

    const response: any = await axios.post(`${url}${queryString}`, filters);

    return {
      data: {
        datasets: response.data.__data,
        labels: response.data.__labels,
      },
      seeMoreUrl: response.data.__link ? cleanUrl(response.data.__link) : '',
      chartType:
        response.data.__meta?.type === 'curves'
          ? ChartType.Line
          : response.data.__meta.type,
      isLoading: false,
      name: response.data.__meta.title,
      description: response.data.__meta.description,
    };
  },
};

/**
 * if the url starts with '/api' then we remove it
 */
const cleanUrl = (link: string) => {
  if (link.startsWith('/api')) {
    return link.substring(4);
  }
  return link;
};

export default ChartService;
