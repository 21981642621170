/**
 * Interface for the generic Chart component
 */
export interface IChart {
  /**
   * url where data is retrieved
   */
  url?: string;
  name?: string;
  description?: string;
  seeMoreUrl?: string;
  data?: IData;
  chartType?: ChartType;
  isLoading?: boolean;
}

/**
 * Interface to render chart data
 */
export interface IData {
  labels: string[];
  datasets: IDataSet[];
}

/**
 * Interface to specify chart format
 */
export interface IDataSet {
  label: string;
  labels?: string[];
  data: number[];
  fill?: boolean;
  backgroundColor?: string;
  borderColor?: string[];
  borderWidth?: number;
}

/**
 * @enum
 */
export enum ChartType {
  /**
   * Line chart
   */
  Line = 'Line',
  /**
   * Bar chart
   */
  Bar = 'Bar',
  /**
   * Horizontal Bar chart
   */
  HorizontalBar = 'HorizontalBar',
  /**
   * Table
   */
  Table = 'Table',
}
