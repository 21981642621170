import React from 'react';
import { useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip, IconButton, Link, Grid, Table, TableContainer, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import { generateAndDownloadCsvWithTimeStamp } from 'utils/helper';
import { IChart } from '../Charts.interface';
import useStyles from './Table.styles';
/**
 * Component used to render Table charts
 * Used by component: [[Chart]]
 *
 * @component
 */
const TableChart = ({ name, description, seeMoreUrl, data }: IChart) => {
  const classes = useStyles();
  const history = useHistory();
  const url = (window.location.href).split('/');
  const urluc = window.location.href;
  const screen = url[url.length-1].split('=')[0];
  let tableText;
  if (urluc.includes('encuestasuc')){
    tableText = (
      <div>
        <p>(*)El promedio de los ítems 1 a 3 refiere al grado de acuerdo de los estudiantes, donde 1 es Totalmente en Desacuerdo y 4 Totalmente de acuerdo.  </p>
        <p>El ítem 4 refiere al grado de dificultad donde 1 es Muy Difícil Difícil y 4 Nada Difícil. </p>
        <p>(**) No se incluye en el promedio las respuestas no sabe/no contesta.</p>
        <p>Las actuaciones con menos de tres respuestas no se evalúan para mantener el anonimato de los estudiantes.</p>
      </div> 
    );
  }else{
    tableText = (
      <div>
        <p>(*) El promedio refiere a la frecuencia con que se desarrollan los ítems consultados, donde 1 es Nunca y 4 es Siempre.</p>
        <p>(**) No se incluye en el promedio las respuestas "Sin elementos para evaluar".</p>
        <p>Las actuaciones con menos de tres respuestas no se evalúan para mantener el anonimato de los estudiantes.</p>
      </div>
    );
  }
  

  if (data) {
    const chartName = name ? name : 'Table Chart';
    const tooltip = description || '';
    const rows:any = data.labels.map( (item, label_index) => {
      const style = label_index % 2 === 0 ? classes.evenRow : classes.oddRow;
      return <TableRow key={label_index}
        hover
        tabIndex={-1}
        className={style}>
        { screen !='detalles?id' && screen != 'detallesuc?id' &&(
          <TableCell
            className={classes.rowText}
            align='left'>
            {`${item}`}
          </TableCell>
        )
        }
        { data.datasets.map( (d, index) => {
          return <TableCell
            key={`cell_${index}`}
            className={classes.rowText}
            align='left'>
            {`${d.data[label_index]}`}
          </TableCell>;
        })}
      </TableRow>;
    });

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Tooltip className={classes.infoIcon} title={tooltip}>
              <IconButton>
                <InfoIcon sx={{ color: '#82858E' }} />
              </IconButton>
            </Tooltip>
            <span className={classes.chartTitle}>{chartName}</span>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            style={{ justifyContent: 'right' }}
          >
            {seeMoreUrl && (
              <div
                style={{
                  border: '1px solid #EAEEF2',
                  borderRadius: '12px',
                  padding: '12px 16px',
                  marginRight: '10px',
                }}
              >
                <Link
                  className={classes.seeMore}
                  onClick={() => {
                    history.push(seeMoreUrl);
                  }}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  Ver más
                </Link>
              </div>
            )}
            <div
              style={{
                border: '1px solid #EAEEF2',
                borderRadius: '12px',
              }}
            >
              <Tooltip title="Descargar csv">
                <IconButton
                  onClick={() =>
                    generateAndDownloadCsvWithTimeStamp(
                      chartName,
                      data.labels,
                      data.datasets,
                    )
                  }
                >
                  <DownloadIcon sx={{ color: '#007BFF' }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableHead className={classes.headerRow}>
                  <TableRow>
                    { screen !='detalles?id' && screen != 'detallesuc?id' && (
                      <TableCell></TableCell>
                    )          
                    }
                    {data.datasets.map((d, index) => {
                      return <TableCell key={index}>
                        { d.label }
                        
                      </TableCell>;
                      
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  { rows }
                  
                </TableBody>
              </Table>
              { 
                screen !='detalles?id' && screen != 'detallesuc?id' &&(
                  <Grid>
                    {tableText}
                  </Grid>
                ) 
              }     
              
            </TableContainer>
          </Grid>
        </Grid>
        <hr />
      </>
    );
  } else {
    return null;
  }
};

export default TableChart;
